import React from 'react';
import clsx from 'clsx';
import Layout from '../components/Layout';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import Title from '../components/Title/Title';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import References from '../components/References/References';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import Accordion from '../components/Accordion/Accordion';
import Image from '../components/Image/Image';
import { TwoColumns, Column } from '../components/TwoColumns/TwoColumns';
import Footnotes from '../components/Footnotes/Footnotes';

import EfficacySecondaryTrialDesignDesktop from '../images/efficacy-second/efficacySecondaryTrialDesignDesktop.inline.svg';
import EfficacySecondaryTrialDesignMobile from '../images/efficacy-second/efficacySecondaryTrialDesignMobile.inline.svg';
import EfficacyPeople from '../images/efficacy-second/efficacyPeople.inline.svg';
import Efficacy21percent from '../images/efficacy-second/efficacy21percent.inline.svg';
import Efficacymonths from '../images/efficacy-second/efficacymonths.inline.svg';
import EnlargeIcon from '../images/enlargeIcon.inline.svg';
import JumpLink from '../components/JumpLink/JumpLink';

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Efficacy | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='Learn more about the LOQTORZI® efficacy demonstrated in the POLARIS-02 trial.'/>
    </>
  )
}

export default function EfficacySecond() {
  const keyPoints = [
    {
      title: (
        <h3>
          Key eligibility and <br className='tablet:hidden' />
          exclusion criteria<sup>2</sup>
        </h3>
      ),
      content: (
        <div>
          <p>
            <strong>Key eligibility criteria</strong> included histologically or cytologically documented R/M NPC refractory to prior standard chemotherapy, disease progression within 6 months after adjuvant chemotherapy or chemoradiotherapy, aged 18 years or older, measurable disease, Eastern Cooperative Oncology Group performance status of 0 or 1, and adequate organ function.
          </p>
          <p>
            <strong>Key exclusion criteria</strong> included anticancer monoclonal antibody therapy within 4 weeks before treatment initiation, any anticancer therapy within 2 weeks before treatment initiation, prior ICI treatment, systemic corticosteroid therapy within 7 days before treatment initiation, known additional malignancies, and active CNS metastases.
          </p>

          <Footnotes className='mt-[2.6rem]' items={[
            {
              bullet: '',
              text: <span>CNS=central nervous system; ICI=immune checkpoint inhibitors;
              <br className='tablet:hidden' /> R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
            }
          ]} />
        </div>
      ),
    }
  ];

  const ctaDescriptionListData = [
    {
      cta: {
        text: <>LOQTORZI<sup>®</sup> at a glance</>,
        url:'/loqtorzi-at-a-glance/'
      },
      text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
        to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
      cta: {
        text: <span>Interactive <br/>
          Prescribing Information </span>,
        url:'/loqtorzi-prescribing-information/'
      },
      text: <div>Explore the details of POLARIS-02
        <br className='desktop:hidden' /> trial data in the interactive
        <br className='desktop:hidden' /> Prescribing Information.</div>
    },
    {
      cta: {
        text: 'Get Updates',
        url: '/get-updates'
      },
      text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
  ];

  const efficacySecondaryTrialDesign = {
    header: <h2>POLARIS-02 TRIAL DESIGN</h2>,
    paragraph1: <span>The efficacy of LOQTORZI<sup>®</sup> was studied in POLARIS-02, an open-label, multicenter, multicohort trial conducted in a single country.* The trial included a total of 172 adult patients with unresectable or metastatic NPC who had received prior platinum-based chemotherapy for treatment of recurrent or metastatic NPC or had disease progression within 6 months of completion of platinum-based chemotherapy administered as neoadjuvant, adjuvant, or definitive chemoradiation treatment for locally advanced disease.<sup>1</sup></span>,
    paragraph2: <span>Patients received LOQTORZI<sup>®</sup> 3 mg/kg intravenously every 2 weeks until disease progression per RECIST v1.1 or unacceptable toxicity.<br />The major efficacy outcomes were confirmed BIRC-assessed ORR and DOR.<sup>1</sup></span>
  };

  const efficacySecondaryEfficacy = {
    header: <h2>POLARIS-02 efficacy</h2>,
    title: <h3>Response was achieved in 21% of patients with LOQTORZI<sup>®</sup><sup>1</sup></h3>,
    subtitle: <span>Patients were heavily pretreated, with a median of 2 prior systemic therapies for R/M disease<sup>1,2</sup>*</span>,
    paragraph1: <span className='font-MontserratMedium'>POLARIS-02 major end points: confirmed ORR and DOR as assessed by BIRC using RECIST v1.1<sup>1</sup></span>,
    footnotes: [
      {
        bullet: <span>*</span>,
        text: <span>Confirmed ORR assessed by BIRC.<sup>1</sup></span>
      },
      {
        bullet: <span>†</span>,
        text: <span>Based on observed duration of response.<sup>1</sup></span>
      },
      {
        bullet: '',
        text: <span>BIRC=blinded independent review committee; CI=confidence interval; NE=not estimable.</span>
      }
    ]
  };

  const pageReferences = [
    <span>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc. </span>,
    <span>Wang F-H, Wei X-L, Feng J, et al. Efficacy, safety, and correlative biomarkers of toripalimab in previously treated recurrent or metastatic nasopharyngeal carcinoma: a phase II clinical trial (POLARIS-02). <em>J Clin Oncol</em>. 2021;39(7):704-712. doi:10.1200/JCO.20.02712</span>
  ];

  return (
    <Layout>

      <Title pageName={'Efficacy'}>
        EFFICACY—<br className='tablet:hidden' />
        second-line<br />
        subsequent <br className='tablet:hidden' />
        treatment
      </Title>

      <DynamicContent header={efficacySecondaryTrialDesign.header}>
        <div className='wrapperContent'>
          <div className='paragraph mt-[2.9rem]'>{efficacySecondaryTrialDesign.paragraph1}</div>
          <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratMedium text-[1rem] text-gray-250 items-center tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>
          <EfficacySecondaryTrialDesignMobile
            className={clsx('w-full h-auto m-auto mb-[3rem] tablet:hidden')}
          />
          <EfficacySecondaryTrialDesignDesktop
            className={clsx(
              'hidden w-full h-auto m-auto mb-[3rem] tablet:block'
            )}
          />
          <div className='paragraph'>{efficacySecondaryTrialDesign.paragraph2}</div>
          <Footnotes items={[{
              bullet: '*',
              text: <span>The POLARIS-02 trial was conducted across 17 sites in China.<sup>2</sup></span>
            },{
              bullet: <sup>†</sup>,
              text: <span>Tumor response assessments were performed every 8 weeks for the first year and every 12 weeks thereafter.<sup>1</sup></span>
            },{
              bullet: '',
              text: <span>BIRC=blinded independent review committee; DOR=duration of response; ORR=overall response rate; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma; RECIST=Response Evaluation Criteria in Solid Tumors.</span>
            }
          ]} />
        </div>
      </DynamicContent>

      <Accordion items={keyPoints} GlobalWrapperClassname={'pb-[3rem]'} />

      <DynamicContent header={efficacySecondaryEfficacy.header}>
        <div className='wrapperContent'>
          <div className='title mt-[2.9rem]'>{efficacySecondaryEfficacy.title}</div>
          <div className='subtitle mt-[1rem]'>{efficacySecondaryEfficacy.subtitle}</div>
          <div className='paragraph mt-[1rem] !text-center tablet:!text-left'>{efficacySecondaryEfficacy.paragraph1}</div>
          <TwoColumns border={true} className='py-[4rem]'>
            <Column>
              <div className='title'>BIRC-assessed ORR<sup>1†</sup></div>
              <Efficacy21percent className='img'></Efficacy21percent>
              <div className='footnotes'>N=172<br />(95% CI, 15%-28%)</div>
              <div className='bulletsContainer mt-[2.5rem]'>
                <div className='bullets'>
                  <ul>
                    <li className='list-disc list-inside'>Complete response rate: 2.3%</li>
                    <li className='list-disc list-inside'>Partial response rate: 19%</li>
                  </ul>
                </div>
              </div>
            </Column>
            <hr className='divider' />
            <Column>
              <div className='title'>BIRC-assessed DOR<sup>1</sup></div>
              <Efficacymonths className='img'></Efficacymonths>
              <div className='footnotes'>n=36<br />(95% CI, 10.3%-NE)</div>
              <div className='bulletsContainer mt-[2.6rem]'>
                <div className='bullets'>
                  <ul>
                    <li className='list-disc list-inside'>Patients with DOR ≥6 months<sup>‡</sup>: 83%</li>
                    <li className='list-disc list-inside'>Patients with DOR ≥12 months<sup>‡</sup>: 39%</li>
                  </ul>
                </div>
              </div>
            </Column>
          </TwoColumns>
        </div>
      </DynamicContent>

      <GlobalWrapper className='px-0 pr-[1.5rem] pb-[3rem] border-left tablet:pl-[3rem]'>
        <Image bg='py-[4rem] bg-lightblue-300'>
          <span className='title !mt-0 mb-[2rem] !text-blue-900'>At least 1 in 5<br />patients achieved a response<sup>1</sup></span>
          <EfficacyPeople></EfficacyPeople>
        </Image>
      </GlobalWrapper>

      <GlobalWrapper className='pl-[0.8rem] pr-[1.5rem] border-left tablet:pl-[3rem]'>
        <Footnotes items={[{
            bullet: '*',
            text: <span>Range: 1-13.<sup>1</sup></span>
          },
          {
              bullet: <sup>†</sup>,
              text: <span>Confirmed ORR assessed by BIRC.<sup>1</sup></span>
            },{
              bullet: <sup>‡</sup>,
              text: <span>Based on observed DOR.<sup>1</sup></span>
            },{
              bullet: '',
              text: <span>BIRC=blinded independent review committee; CI=confidence interval; DOR=duration of response; NE=not estimable; R/M=recurrent/metastatic; ORR=overall response rate; RECIST=Response Evaluation Criteria in Solid Tumors.</span>
            }
        ]} />
      </GlobalWrapper>

      <JumpLink content={{title:'See POLARIS-02 safety', link:'/loqtorzi-safety-subsequent-line-treatment/', class:'special-top'}}/>

      <References items={pageReferences} border={true}></References>

      <CtaDescList content={ctaDescriptionListData} />
    </Layout>
  );
}