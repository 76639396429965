import React from 'react';
import * as styles from './Accordion.module.css';
import clsx from 'clsx';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';
import AccordionItem from './AccordionItem';

export default function Accordion({ className, GlobalWrapperClassname, items, idOpen }) {
  let list = items.map((item, index) => <AccordionItem key={index} index={index} id={item.id} title={item.title} content={item.content} idOpen={idOpen} />);
  return (
    <div className={ clsx(className) }>
      <GlobalWrapper className={clsx('border-left', GlobalWrapperClassname)}>
        <ul className={styles.list}>
          { list }
        </ul>
      </GlobalWrapper>
    </div>
  );
}