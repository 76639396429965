import React from 'react';
import * as styles from '../TwoColumns/TwoColumns.module.css';
import clsx from 'clsx';

export function TwoColumns({className, border=false, children}) {
  return (
    <div className={clsx(styles.twoColumns, border && styles.border, className )}>
      { children }
    </div>
  );
}

export function Column({children, className}) {
  return (
    <div className={clsx(styles.column, className)}>
      { children}
    </div>
  );
}