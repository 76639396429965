import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import * as styles from './Accordion.module.css';
import ChevronImg from '../../images/chevron.inline.svg';

export default function AccordionItem({ index, id, title, content, idOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(id === idOpen) {
      setIsOpen(true);
    }
  }, [id, idOpen]);

  const toggleAccordion_handler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li key={ index } className={clsx(isOpen && styles.open)}>
      <button className={ styles.actionButton } onClick={toggleAccordion_handler}>
        <h3 className={clsx(styles.title, 'mr-auto')}>{ title }</h3><ChevronImg className={styles.chevron} />
      </button>
      <div className={ styles.container }>
        <div className={ styles.content }>
          { content }
        </div>
      </div>
    </li>
  );
}